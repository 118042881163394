import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import ThemeGeneralSettingsContext from "./ThemeGeneralSettingsContext.js";
import history from './history';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import axios from "axios";
import API from "./api";

import './App.scss';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import StartPage from './components/Pages/StartPage/StartPage';
import ItBetreuung from "./components/Pages/ItBetreuung/ItBetreuung";
import Referenzen from "./components/Pages/Referenzen/Referenzen";
import Contact from "./components/Pages/Contact/Contact";
import CookieOptIn from "./components/Pages/CookieOptIn/CookieOptIn";
import NotFound from "./components/Pages/NotFound/NotFound";
import Partner from "./components/Pages/Partner/Partner";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import TextPage from "./components/Pages/TextPage/TextPage";
import WebEntwicklung from "./components/Pages/WebEntwicklung/WebEntwicklung";
import Hosting from "./components/Pages/Hosting/Hosting";
import CVPage from "./components/Pages/CVPage/CVPage";
import WebDesign from "./components/Pages/WebDesign";
import Blog from "./components/Pages/Blog";
import AboutUs from "./components/Pages/AboutUs";
import ECommerce from './components/Pages/ECommerce/ECommerce';
import SuccessStoryBw from "./components/Pages/SuccessStoryBw/SuccessStoryBw"
import SuccessStoryShort from "./components/Pages/SuccessStoryShort/SuccessStoryShort"
import CorporateWebsites from './components/Pages/CorporateWebsites/CorporateWebsites';
import Magento from './components/Pages/Magento/Magento';
import Wordpress from './components/Pages/Wordpress/Wordpress';
import Typo3 from './components/Pages/Typo3/Typo3';
import Shopware from './components/Pages/Shopware/Shopware';
import Glossary from './components/Pages/Glossary/Glossary';
import Usercentrics from './components/Pages/Usercentrics/Usercentrics';
import { Helmet } from 'react-helmet';

class App extends Component {

    state = {
        headerOptions: {
            pageId: '',
            darkLogo: false,
            darkNav: false
        },
        contactButton: true,
        contactBlock: true,
        preventContactBlockAnimation: false,
        certificates: {
            shopware_developer: {},
            shopware_designer: {},
            usercentrics: {},
            erecht24: {},
            we_train: {},
        },
        contact_form: {}
    };

    setContactElements = (
        statusButton = true,
        statusBlock = true,
        animationStatus = true
    ) => {
        this.setState({
            contactButton: statusButton,
            contactBlock: statusBlock,
            preventContactBlockAnimation: animationStatus,
        });
    };

    setHeaderOptions = (pageId = '', logoStatus = false, menuButtonStatus = false) => {
        this.setState({
            headerOptions: {
                ...this.state.headerOptions,
                darkLogo: logoStatus,
                darkNav: menuButtonStatus,
                pageId: pageId
            }
        });
    };

    loadThemeGeneralSettingsData = () => {
        axios.get(API.basePath+'/wp-json/acf/v3/options/theme-general-settings').then(response => {
            this.setState({
                certificates: response.data.acf.certificates_items,
                contact_form: response.data.acf.contact_form
            });
        })
        .catch(error => {
            console.error(error)
        })
    }

    componentDidMount() {
        this.loadThemeGeneralSettingsData()
    }

    render() {
        return (
            <ThemeGeneralSettingsContext.Provider value={{certificates: this.state.certificates, contact_form: this.state.contact_form}}>
                <Helmet>
                    <script>
                    {`if(window.location.pathname=="/impressum" || window.location.pathname=="/datenschutz" || window.location.pathname=="/tsp") {
                        window.UC_UI_SUPPRESS_CMP_DISPLAY=true
                    }`}
                    </script>
                    <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="rjREFMS-M" async></script>
                    {process.env.REACT_APP_GTM_ID && (
                        <script type="text/plain" data-usercentrics="Google Tag Manager">{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer',process.env.REACT_APP_GTM_ID)}</script>
                    )}
                </Helmet>
                <div id="page" className="page">
                    <Router history={history}>
                        <ScrollToTop setHeaderOptions={this.setHeaderOptions} />
                        <Header options={this.state.headerOptions} />
                        <div className="page__content">
                            <Switch>
                                {/* redirect (remove slash) if url  has a trailing slash */}
                                {history.location.pathname.endsWith('/') && <Redirect from="/:url*(/+)" to={history.location.pathname.replace(/\/+$/, "")} />}

                                <Route
                                    exact path="/"
                                    render={() => (
                                        <StartPage
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/it-betreuung'
                                    render={() => (
                                        <ItBetreuung
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route exact path="/webdesign" component={WebDesign} />
                                <Route
                                    exact path="/blog"
                                    render={props => (
                                        <Blog {...props}
                                            setContactElements={this.setContactElements}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/bw-online-shop"
                                    render={() => (
                                        <SuccessStoryBw
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/office-people"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'office-people'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/volve"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'volve'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/olymp"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'olymp'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/kostueme"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'kostueme'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/fameonme"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'fameonme'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/referenzen/atrava"
                                    render={() => (
                                        <SuccessStoryShort
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            pageSlug={'atrava'}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/blog/:slug"
                                    render={props => (
                                        <Blog {...props}
                                            setContactElements={this.setContactElements}
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/blog/autoren/:slug"
                                    render={props => (
                                        <Blog {...props}
                                            setContactElements={this.setContactElements}
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/referenzen'
                                    render={() => (
                                        <Referenzen
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/kontakt'
                                    render={() => (
                                        <Contact
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/dsgvo-cookie-consent'
                                    render={() => (
                                        <CookieOptIn
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/partner'
                                    render={() => (
                                        <Partner
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/softwareentwicklung/magento" 
                                    render={() => (
                                        <Redirect to="/webentwicklung/magento"/>
                                    )}
                                />
                                <Route
                                    exact path="/webentwicklung/magento"
                                    render={() => (
                                        <Magento
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/softwareentwicklung/wordpress" 
                                    render={() => (
                                        <Redirect to="/webentwicklung/wordpress"/>
                                    )}
                                />
                                <Route
                                    exact path="/webentwicklung/wordpress"
                                    render={() => (
                                        <Wordpress
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />

                                {/*
                                    TODO: Add page in wordpress admin before use Typo3 component.
                                */}
                                {/*<Route*/}
                                {/*    exact path="/webentwicklung/typo3"*/}
                                {/*    render={() => (*/}
                                {/*        <Typo3*/}
                                {/*            setContactElements={*/}
                                {/*                this.setContactElements*/}
                                {/*            }*/}
                                {/*            setHeaderOptions={this.setHeaderOptions}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <Route 
                                    exact path="/softwareentwicklung/shopware" 
                                    render={() => (
                                        <Redirect to="/webentwicklung/shopware"/>
                                    )}
                                />

                                <Route
                                    exact path="/webentwicklung/shopware"
                                    render={() => (
                                        <Shopware
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/softwareentwicklung" 
                                    render={() => (
                                        <Redirect to="/webentwicklung"/>
                                    )}
                                />
                                <Route
                                    exact path="/webentwicklung"
                                    render={() => (
                                        <WebEntwicklung
                                            setContactElements={
                                                this.setContactElements
                                            }
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/hosting'
                                    render={() => (
                                        <Hosting
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/ueber-uns'
                                    render={() => (
                                        <AboutUs
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/e-commerce" 
                                    render={() => (
                                        <Redirect to="/onlineshop-erstellen-lassen"/>
                                    )}
                                />
                                <Route
                                    exact path='/onlineshop-erstellen-lassen'
                                    render={() => (
                                        <ECommerce
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route 
                                    exact path="/gdpr-cookie-consent" 
                                    render={() => (
                                        <Redirect to="/cookie-banner-integration"/>
                                    )}
                                />
                                <Route
                                    exact path='/cookie-banner-integration'
                                    render={() => (
                                        <Usercentrics
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path='/website-erstellen-lassen'
                                    render={() => (
                                        <CorporateWebsites
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/glossar"
                                    render={props => (
                                        <Glossary
                                            {...props}
                                            setContactElements={this.setContactElements}
                                        />
                                    )}
                                />
                                <Route
                                    exact path="/glossar/:slug"
                                    render={props => (
                                        <Glossary
                                            {...props}
                                            setContactElements={this.setContactElements}
                                            setHeaderOptions={this.setHeaderOptions}
                                        />
                                    )}
                                />
                                <Route exact path={["/datenschutz", "/impressum", "/agb", "/egd", "/av", "/av-cmp", "/spv", "/agb-cmp", "/egam", "/tsp"]} render={props => (
                                    <TextPage
                                        key={props.location.pathname}
                                        {...props}
                                        setContactElements={
                                            this.setContactElements
                                        }
                                    />
                                )} />
                                <Route
                                    path='/:cvpage'
                                    render={(props) => (
                                        <CVPage
                                            {...props}
                                            setHeaderOptions={this.setHeaderOptions}
                                            setContactElements={
                                                this.setContactElements
                                            }
                                        />
                                    )}
                                />
                                <Route path='*' component={NotFound} />
                            </Switch>
                        </div>
                        <Footer
                            contactButtonStatus={this.state.contactButton}
                            contactBlockStatus={this.state.contactBlock}
                            preventContactBlockAnimation={
                                this.state.preventContactBlockAnimation
                            }
                        />
                    </Router>
                </div>
            </ThemeGeneralSettingsContext.Provider>
        );
    }
}

export default App;
